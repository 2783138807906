import React from 'react';
import FrHeader from '../FrHeader';
import FrFooter from '../Footer/FrFooter';
import './Help.css';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {  CardActionArea, CardHeader} from '@mui/material';


// create customer support

function FrHelp () {
    return (
        <>
            <FrHeader />
            <div className="jumbotron" id="account">
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                            <h1>Services clients</h1>
                        </div>
                    </div>
                </div>
            </div>
           
           <div className="container" id="help-container">

                <div className="row">
                    <div className="col-12">
                        <h2>Utiliser ma carte chompp.</h2>
                    </div>
                    <div className="col-md-4" id='questions'>
                        <Card>
                            <CardHeader title="Comment obtenir la réduction? " />
                            <CardActionArea>
                                <CardContent>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                    Montre ta carte chompp au serveur. <a href="/comment">Plus d'informations ici</a>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </div>
                    
                    <div className="col-md-4" id='questions'>
                        <Card>
                            <CardHeader title=" A quelle fréquence est-ce que je peux utiliser ma carte?" />
                            <CardActionArea>
                                <CardContent>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                    Tous les jours où le restaurant offre la réduction    
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </div>
                    
                    
                </div>
            </div>
            
            <div className="container" id="help-container">

                <div className="row">
                    <div className="col-12">
                        <h2>Questions générales</h2>
                    </div>
                    <div className="col-md-4" id='questions'>
                        <Card>
                            <CardHeader title="Est-ce que chompp sera gratuit?" />
                            <CardActionArea>
                                <CardContent>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                    Pour le lancement initial, oui ça sera gratuit. Après, il y aura un léger paiement mensuel. 
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        
                    </div>
                    
                    
                    
                </div>
            </div>
            <div className="container" id="help-container">

                <div className="row">
                    <div className="col-12">
                        <h2>Questions à propos du paiement</h2>
                    </div>
                    <div className="col-md-4" id='questions'>
                        <Card>
                            <CardHeader title=" Bientot" />
                            
                            
                        </Card>
                    </div>
                    
                    
                </div>
            </div>
             
            <div className="container">
                <div className="row">
                    <div className="col-12 d-flex justify-content-center" id="contact">
                        <h2>Toujours besoin d’aide? <a href="mailto:harry@chompp.ca">Nous contacter</a></h2>
                    </div>
                    

                </div>
            </div>

            
            <FrFooter />
                
        </>
    )
}
export default FrHelp;