import React, { Component } from 'react';
import HeaderLoggedIn from '../HeaderLoggedIn';
import Welcome from './Welcome/Welcome';
import Footer from '../Footer/Footer';
import HowItWorks from './HowItWorks/HowItWorks';
import Contact from './Contact/Contact';
import Partners from './Partners/Partners';


// Create Main component
class Main extends Component {
    render() {
        return (
            <> 

                <HeaderLoggedIn />
                <Welcome />
                <HowItWorks />
                
                
                <Partners />
                <Contact />
                
                <Footer />
                
            </>

        );
    }
}
export default Main;

