import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, logout } from '../../utils/firebase';

import './Footer.css';

function FrFooter(){

    // if user is not logged in, hide the footer
    const [user, loading] = useAuthState(auth);
    if (!user) return (
        <footer className="footer">
            <div className="footer-copyright">
                <div className="container">
                    <div className="row ">
                        <div className="col-12 d-flex justify-content-center ">
                            <p className="white-text">
                                chompp © 2022 - Site créé par chompp.

                            </p>
                        </div>
                        
                    </div>
                </div>
            </div>


        </footer>

    );

    

    return(

    	<footer className="footer">	
            
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 m-b-30">
                        <div className="footer-title m-t-5 m-b-20 p-b-8">
                            A Propos 
                        </div>	
                        <p className="white-text">
                        chompp. est un club pour ceux qui adorent aller au restaurant. On travaille avec des restaurants afin d’offrir des réductions à nos membres, ceux qui permets aux restaurants d’avoir plus de clients - et à nos membres de faire des économies. 

                        </p>
                    </div>
                    
                    <div className="col-lg-3 m-b-30">
                        <div className="footer-title m-t-5 m-b-20 p-b-8">
                            Liens
                        </div>	
                        <div className="footer-links">
                            <a href="/tableau-de-bord">
                                Tableau de bord
                            </a>
                            <a href="/propos">
                                A Propos
                            </a>
                            <a href="/compte">
                                Mon Compte
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 m-b-30">
                        <div className="footer-title m-t-5 m-b-20 p-b-8">
                        Service client 
                        </div>	
                        <div className="footer-links">
                            <a href="/comment">
                            Comment utiliser ma carte chompp
                            </a>
                            <a href="/aide">
                            FAQ
                            </a>
                        
                            <a href="/aide">
                            Nous contacter
                            </a>
                        </div>

                        
                    </div>
                </div>
                 
                


            </div>
            
            <div className="footer-copyright">
                <div className="container">
                    <div className="row ">
                        <div className="col-12 d-flex justify-content-center ">
                            <p className="white-text">
                                chompp. © 2022 - Site créé par chompp.
                            </p>
                        </div>
                        
                    </div>
                </div>
            </div>


    </footer>
	
    );
}

export default FrFooter;




