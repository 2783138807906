import HeaderLoggedIn from "../HeaderLoggedIn";
import Footer from "../Footer/Footer";
import './About.css';
import Contact from "../Home/Contact/Contact";


// create About page
function About() {
    return (
        <>
            <HeaderLoggedIn />
            <div className="jumbotron" id="aboutus">
                <div className="container">

                        <div className="row col-sm-8  m-auto">
                            <div id="aboutid">
                                <h1>About Us</h1>
                            </div>
                            <div id="aboutintro">
                                <p>
                                chompp. is a service for people who love to go out. We work with restaurants to offer discounts
                                    to our members, which in turn allows restaurants to get more customers and members to save money. 
                                 </p>

                            </div>
                            
                            
                        </div>

                    
                    
                </div>
            </div>
            
            <div className="container" id="aboutcontent">
                <div className="row">
                    <div className="col-12 ">
                        <h1>The Project</h1><hr/>
                    </div>
                    
                    <div className="col-md-8 m-auto justify-content-center">
                        
                        <p>There are five problems chompp. aims to solve:</p> 
                        <ul>
                            <li>
                                <p>
                                    <strong>1. </strong>
                                     Restaurants losing money during quiet hours
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>2. </strong>
                                     Diners wishing to save money when they go out 
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>3. </strong>
                                     Fewer people eating out post covid
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>4. </strong>
                                     Local restaurants wanting to expand to new customers
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>5. </strong>
                                     Customers who don’t know where to eat and relying on Google or Yelp
                                </p>

                            </li>
                        </ul>
                        <p>
                            chompp.’s core mission is to connect customers and restaurants.
                        </p>
                        <hr/>
                        <p>Signed,</p>
                        <p> Harry North (Founder)</p> 


                    </div>
                    
                    <div className="container" id="culturecontent">
                        <div className="row">
                            <div className="col-12 ">
                                <h1>Culture and Values</h1><hr/>
                            </div>
                            
                            <div className="col-md-8 m-auto justify-content-center">
                                <p> There are three ideas which underpin chompp.  </p>
                                <ul >
                                    <li>
                                        <p><strong>1. Experience-driven</strong></p>
                                    </li>
                                    <p>chompp. is a service driven by providing and enabling experiences. Sharing a table with friends and
                                        family, enjoying a meal or a drink, saving money, living in the moment, and creating memories is what chompp. is all about. 
                                        Live for interesting conversations. 
                                    </p>
                                        
                                    
                                    <li>
                                        <p><strong>2. Kindness</strong></p>
                                    </li>
                                    <p> chompp. is a service for everyone. Not treating those who are different to you as less than makes for a stronger community and a richer experience 
                                        - for everyone. Be friendly to your servers, be friendly to your customers. Don't be afraid to buy your server a drink. 
                                        Who knows, maybe you'll drinking and sharing stories all night.

                                    </p>
                                    <li>
                                        <p><strong>3. Supporting Local </strong></p>
                                    </li>
                                    <p> chompp. is committed to supporting local. We partner exclusively with local businesses. 
                                        For this reason, when you use chompp. you directly support local businesses. Helping to build communities, not skyskrappers.
                                        We encourage all members to support local businesses.
                                    
                                    </p>
                                </ul> 
                                

                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <hr />
                        
                    </div>

                    
                </div>
            </div>
            <Contact />
            <Footer />
        </>
    




)}
export default About;
