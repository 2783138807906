import React, { Component } from 'react';

import FrHeader from '../FrHeader';
import FrWelcome from './Welcome/FrWelcome';
import FrFooter from '../Footer/FrFooter';
import FrHowItWorks from './HowItWorks/FrHowItWorks';
import FrContact from './Contact/FrContact';
import FrPartners from './Partners/FrPartners';



// Create Main component
class FrMain extends Component {
    render() {
        return (
            <> 
            <FrHeader />
            <FrWelcome />
            <FrHowItWorks />
            <FrPartners />
            <FrContact />
            <FrFooter />


                
                
            </>

        );
    }
}
export default FrMain;