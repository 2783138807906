import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";
import {Link } from "react-router-dom";

import { auth, db, logout, addSavings } from "../../utils/firebase";
import { query, collection, getDocs, where } from "firebase/firestore";

import RestaurantCard from "./Restaurants/RestaurantCard/RestaurantCard";
import Savings from "./Savings/Savings";


import Footer from "../Footer/Footer";
import HeaderLoggedIn from "../HeaderLoggedIn";


function Dashboard() {
  const [user, loading] = useAuthState(auth);
  const [name, setName] = useState("");
  const navigate = useNavigate();

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();

      setName(data.name);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/en");


    fetchUserName();
  }, [user, loading, navigate]);



  return (

              <>
                <HeaderLoggedIn />
                <div className="d">
                    
                    <div className="container" id="dashboard">
                        <div className="row">
                            <div className="col-12" id="titles">
                                <h6>Hey {name}!</h6>
                                <h6 id="wel">Welcome to chompp.</h6>
                                
                                
                                  
                            </div>
                            <div className="col-12 " id="cards">
                                
                                  <Link to="/qr">
                                    <button className="btn btn-primary"  id="gold">Download my Dining Card</button>
                                  </Link>
                                
                                
                                  < Link to="/how">
                                    <button className="btn" id="grey">How to use my chompp. card</button>
                                  </Link>
                                
                                
                            </div>
                        </div>
                    </div>
                    
                    <RestaurantCard/>
                    
                    <Savings/>

                    
                    
                    
                     
                    <div className="container" id="help">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                                <h2>Need help? <a href='/help'>Visit our Help Desk </a></h2>
                            </div>
                        </div>
                    </div>

                    
                </div>
                <Footer/>
              </>
  );
}

export default Dashboard;