import React from 'react';
import HeaderLoggedIn from '../HeaderLoggedIn';
import Footer from '../Footer/Footer';
import './Help.css';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea, CardHeader} from '@mui/material';


// create customer support

function Help () {
    return (
        <>
            <HeaderLoggedIn />
            <div className="jumbotron" id="account">
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                            <h1>Help Desk</h1>
                        </div>
                    </div>
                </div>
            </div>
           
           <div className="container" id="help-container">

                <div className="row">
                    <div className="col-12">
                        <h2>Using your Dining Card</h2>
                    </div>
                    <div className="col-md-4" id='questions'>
                        <Card>
                            <CardHeader title="How do I get my discount/dining card?" />
                            <CardActionArea>
                                <CardContent>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Show your card to the server. And get them to scan the QR code. More info <a href="/How">here</a>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </div>
                    
                    <div className="col-md-4" id='questions'>
                        <Card>
                            <CardHeader title="How often can I use my card?" />
                            <CardActionArea>
                                <CardContent>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        You can use your dining card as many times as you want per month!   
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </div>

                    
                </div>
            </div>
            
            <div className="container" id="help-container">

                <div className="row">
                    <div className="col-12">
                        <h2>General Questions</h2>
                    </div>
                    <div className="col-md-4" id='questions'>
                        <Card>
                            <CardHeader title="Will chompp. be free?" />
                            <CardActionArea>
                                <CardContent>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        For the inital 2 month pilot, yes it will be free. After that, there will be a small fee 
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        
                    </div>
                    
                    
                </div>
            </div>
            <div className="container" id="help-container">

                <div className="row">
                    <div className="col-12">
                        <h2>Payment Questions</h2>
                    </div>
                    <div className="col-md-4" id='questions'>
                        <Card>
                            <CardHeader title="Coming Soon" />
                            
                            
                        </Card>
                    </div>
                    
                    
                </div>
            </div>
             
            <div className="container">
                <div className="row">
                    <div className="col-12 d-flex justify-content-center" id="contact">
                        <h2>Still need help? <a href="mailto:harry@chompp.ca">Contact Us</a></h2>
                    </div>
                    

                </div>
            </div>

            
            <Footer />
                
        </>
    )
}
export default Help;