// import react 
import React from 'react';

import {Link } from "react-router-dom";

import './Welcome.css';

import { auth} from "../../../utils/firebase";

import { useAuthState } from "react-firebase-hooks/auth";



function Welcome () {
    
    // if user is logged in, link to dashboard
    // if user is not logged in, link to login page
    const [user] = useAuthState(auth);

    const loggedIn = () => {
        if (user) {
            return (
                <Link to="/dashboard">
                    <button className='btn btn-primary col-12' id="Sub">Go to Dashboard</button>
                </Link>
            )
        } else {
            return (
                <Link to="/register">
                    <button className='btn btn-primary col-12' id="Sub">Join for free!</button>
                </Link>
            )
        }
    }

        return (
            <>
                <div className='jumbotron' id="buy">
                    <div className="container">
                        <div className="row justify-content">
                            <div className="col-12  col-lg-8">
                                
                                <div className="c">
                                    <div className="card-header">
                                        <h2>Receive discounts on food and drink during quiet hours  </h2>
                                    </div>
                                    <div className="card-body">
                                            <p>Introducing the chompp dining card: discounts and personalized recommendations for local restaurants in Montreal!
                                            </p>
                                    </div>
                                    
                
                                    <div className="card-footer d-flex justify-content-center">
                                        {loggedIn()}
                                    </div>
                                </div>
                            </div>
                        
                                  

                            
                        </div>

                     </div>   
                    
                </div>
                <div className='container' id="welcome">
                    <div className="row align-items-center">
                        <div className="col-12 col-sm-12" id="Welcome">
                            <h1> chompp. is currently paused </h1>
                        </div>
                    </div>
                </div>
                
            </>

            


        );
    }

export default Welcome;
