import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';

import {Link } from 'react-router-dom';
// import logout function from firebase.js
import { auth, logout } from '../utils/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

import './header.css';






// create header component
function HeaderLoggedIn() {

    // create a drop down navbar  
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(!open);
    }
    const handleClose = () => {
        setOpen(false);
    }
    const handleLogout = () => {
        logout();
    }

    const [user, loading] = useAuthState(auth);
    if (!user) return (
        <> 
            <Navbar className="navbar" expand="md">
                <Container fluid>
                    <Navbar.Brand className="navbar-brand" href="/en"><h3>chompp.</h3></Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse className="mr-auto" id="navbarScroll">
                        <Nav className="me-auto" >
                            <Nav.Link className="nav-itm" href="/about"><h3>about</h3></Nav.Link>
                        </Nav>
                        <Nav className="ml-auto" id="left">
                            <Nav.Link className="nav-itm" href="/"><h3>en/fr</h3></Nav.Link>
                            <Nav.Link className="nav-itm" href="/login"><h3>Sign up/ Sign in</h3></Nav.Link>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        
        </>
    );

    

    return (

        <Navbar className="navbar" expand="md">
            <Container fluid>
                <Navbar.Brand className="navbar-brand" href="/en"><h3>chompp.</h3></Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse className="mr-auto" id="navbarScroll">
                <Nav className="me-auto" 
                    
                >
                    <Nav.Link className="nav-itm" href="/dashboard"><h3>dashboard</h3></Nav.Link>
                    <Nav.Link className="nav-itm" href="/about"><h3>about</h3></Nav.Link>
                    
                    
                </Nav>
                <Nav className="ml-auto" id="left">
                    <Nav.Link className="nav-itm" href="/"><h3>en/fr</h3></Nav.Link>
                    <Nav.Link  className="nav-itm" href="/settings"><h3>my account</h3></Nav.Link>
                </Nav>

                
                </Navbar.Collapse>
            </Container>
        </Navbar>
         
    );
}

export default HeaderLoggedIn;