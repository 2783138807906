import React, { Component } from 'react';
import './Contact.css';

class FrContact extends Component {
    render() {
        return (
            // Create Who we are component
             
                <div className="container" id="Contact">
                    <div className="row content">
                        <div className="col-12 d-flex justify-content-center" >
                            <h2>Plus de questions ou intéressés en un partenariat?<a href="mailto: harry@chompp.ca"> Prendre contact</a></h2>
                        </div>
                    </div>
                </div>
            
        );
    }
}
export default FrContact;