import './App.css';

//english language
import Main from './components/Home/Main';
import Login from './components/Auth/Login/Login';
import Register from './components/Auth/Register/Register';
import Reset from './components/Auth/Reset/Reset';
import Dashboard from './components/Dashboard/Dashboard';
import Settings from './components/Settings/settings';
import MyCard from './components/Dashboard/MyCard/MyCard';
import Url from './components/Url/Url';
import Rules from './components/Rules/Rules';
import How from './components/Dashboard/HowToUseCard/HowToUseCard';
import ViewAll from './components/Dashboard/Restaurants/ViewAll/ViewAll';
import Help from './components/Help/Help';
import About from './components/About/About';
import QR from './components/userUrl/userUrl';
import Launching from './components/Launching/Launching';
import MyTrips from './components/Dashboard/MyTrips/MyTrips';
import Language from './components/Language/Language';
import Done from './utils/done';
import Terms from './components/Terms/Terms';




// french language 
import FrMain from './components/Home/FrMain';
import FrDashboard from './components/Dashboard/FrDashboard';
import FrAbout from './components/About/FrAbout';
import FrRegister from './components/Auth/Register/FrRegister';
import FrLogin from './components/Auth/Login/FrLogin';
import FrReset from './components/Auth/Reset/FrReset';
import FrUserUrl from './components/userUrl/FruserUrl';
import FrUrl from './components/Url/FrUrl';
import FrHow from './components/Dashboard/HowToUseCard/FrHowToUseCard';
import FrSettings from './components/Settings/Frsettings';
import FrHelp from './components/Help/FrHelp';
import FrViewAll from './components/Dashboard/Restaurants/ViewAll/FrViewAll';
import FrMyTrips from './components/Dashboard/MyTrips/FrMyTrips';
import FrTerms from './components/Terms/FrTerms';



import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


function App() {
  return (
    <Router>
      <Routes>
        
          <Route path="/en" element={<Main />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/myurl/:uid" element={<Url />} />
          <Route path="/rules" element={<Rules />} />
          <Route path="/how" element={<How />} />
          <Route path="/viewall" element={<ViewAll />} />
          <Route path="/help" element={<Help />} />
          <Route path="/about" element={<About />} />
          <Route path="/qr" element={<QR />} />
          <Route path="/launchingsoon" element={<Launching />} />
          <Route path="/mytrips" element={<MyTrips />} />
          <Route path="/done" element={<Done />} />
          
          
          <Route path="/" element={<FrMain />} />
          <Route path="/tableau-de-bord" element={<FrDashboard />} />
          <Route path="/propos" element={<FrAbout />} />
          <Route path="/frreg" element={<FrRegister />} />
          <Route path="/frlgn" element={<FrLogin />} />
          <Route path="/reinitialiser" element={<FrReset />} />
          <Route path="/frqr" element={<FrUserUrl />} />
          <Route path="/frurl/:uid" element={<FrUrl />} />
          <Route path="/comment" element={<FrHow />} />
          <Route path="/compte" element={<FrSettings />} />
          <Route path="/aide" element={<FrHelp />} />
          <Route path="/voirtous" element={<FrViewAll />} />
          <Route path="/messorties" element={<FrMyTrips />} />
          <Route path="/fr" element={<FrMain />} />
          








        </Routes>
        
      
    </Router>
  );
}

export default App;
