import React, { useEffect, useState } from "react";
import  "firebase/storage";


import { db} from '../../../../utils/firebase';
import { query, collection, getDocs } from "firebase/firestore";

import './RestaurantCard.css';

// get restaurant data from firebase and create a card for each restaurant

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea} from '@mui/material';

function FrRestaurantCard() {

    // get restaurant data from firebase and create a card for each restaurant
    const [restaurants, setRestaurants] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const q = query(collection(db, "restuarants"));
                const doc = await getDocs(q);
                const data = doc.docs.map(doc => doc.data());
                setRestaurants(data);
                
            } catch (error) {
                
            }

        }
        fetchData();
    }
    , []);

    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const currentTimeNumber = hours*100 + minutes;
    //current day of the week
    const cdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const currentDay = cdays[currentTime.getDay()];

    // if currentDay is in the restaurant's days array and if the current time is between the restaurant's opening and closing time, then the restaurant is open
    const isOpen = (restaurant) => {
        console.log(currentDay);
        // if currentDay is in the restaurant's days array
        if (restaurant.days.includes(currentDay) && currentTimeNumber >= restaurant.timeStart && currentTimeNumber <= restaurant.timeEnd) {
            return true;
        } else {
            return false;
        }
    }

    // filter cards to have open restaurants first
    const openRestaurants = restaurants.filter(restaurant => isOpen(restaurant));
    const closedRestaurants = restaurants.filter(restaurant => !isOpen(restaurant));
    const sortedRestaurants = openRestaurants.concat(closedRestaurants);


    return (
        <>
         <div className="container" id="ccontainer">
        <div className="container">
            <div className="row content">
                <div className="col-12" id="head">
                    <h1>Restaurants choisis pour toi</h1>
                </div>
            </div>
        </div>
        
        <div className="container" id="restaurants-container" >
            <div className='row' id="categories">
                <div className="col-12">
                    <div className='row'>
                        
                        <div className="col-4 d-flex">
                            
                            
                            <Card className="restaurantCard" sx={{ maxWidth: 345 }}>
                                <CardActionArea>
                                    <CardMedia

                                        component="img"
                                        height="140"
                                        image="https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM="
                                        alt="bowhead pub"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h5">
                                            chompp is currently on pause
                                        </Typography>
                                        
                                    </CardContent>
                                </CardActionArea>
                            </Card>

                        </div>
                    
                    
                    </div>
                </div>
            </div>
            
                
        </div>
       

        </div>
        
        
        

        </>
    );
}
/* 
                            {sortedRestaurants.map(restaurant => (
                                
                                
                                <Card className="restaurantCard" key={restaurant.id} >

                                    <CardActionArea> 
                                        
                                    {isOpen(restaurant) ? 
                                        <CardContent className="open"
                                        color="success"
                                        style={{
                                            backgroundColor: "#1DBF30", 
                                        }}
                                        >
                                            <Typography variant="h7" component="h7">
                                                {restaurant.frsdiscount}
                                            </Typography>
                                        </CardContent>
                                    
                                           
                                        
                                        : 
                                        <CardContent 
                                        className="closed"
                                        color="error"
                                        style={{
                                            backgroundColor: "#f14144",
                                        }}
                                        >  
                                        <Typography variant="h7" component="h7">
                                                {restaurant.frsdiscount}
                                            </Typography>
                                        </CardContent>
                                        }
                                         

                                        <CardMedia
                                            component="img"
                                            height="170"
                                            image={restaurant.image}
                                            title={restaurant.name}
                                            
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                {restaurant.name}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                {restaurant.frenchdiscount} 
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">

                                                <Button>
                                                    <a href={restaurant.link}>
                                                    Voir le restaurant
                                                    </a>
                                                </Button>
                                            </Typography>
                                            

                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            ))}
                        */  


     
export default FrRestaurantCard;
