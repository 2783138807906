import FrHeader from "../FrHeader";
import FrFooter from "../Footer/FrFooter";
import './About.css';
import FrContact from "../Home/Contact/FrContact";


// create About page
function FrAbout() {

    // iterate 


    return (
        <>
            <FrHeader />
            <div className="jumbotron" id="aboutus">
                <div className="container">

                        <div className="row col-sm-8  m-auto">
                            <div id="aboutid">
                                <h1>A Propos</h1>
                            </div>
                            <div id="aboutintro">
                                <p>
                                chompp. est un club pour ceux qui adorent aller au restaurant. On travaille avec des restaurants afin d’offrir des réductions à nos membres, ceux qui permets aux restaurants d’avoir plus de clients - et à nos membres de faire des économies.
                                 </p>
                            </div>   
                        </div>
                </div>
            </div>
            <div className="container" id="aboutcontent">
                <div className="row">
                    <div className="col-12 ">
                        <h1>Le Projet</h1><hr/>
                    </div>
                    
                    <div className="col-md-8 m-auto justify-content-center">
                        
                        <p>chompp. vise à résoudre cinq problèmes:</p> 
                        <ul>
                            <li>
                                <p>
                                    <strong>1. </strong>
                                    La perte d’argent des restaurants lors d’heures creuses. 
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>2. </strong>
                                    Les prix des restaurants qui causent barrage aux étudiants. 
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>3. </strong>
                                    Moins de monde qui va au restaurant après le covid.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>4. </strong>
                                    Le désir de restaurants d'élargir leurs clientèles. 
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>5. </strong>
                                    Les clients qui ne savent pas où sortir - et qui souhaitent trouver de nouveaux restaurants.
                                </p>

                            </li>
                        </ul>
                        
                        <hr/>
                        <p>Signé,</p>
                        <p> Harry North (Fondateur)</p> 


                    </div>
                    <div className="container" id="culturecontent">
                        <div className="row">
                            <div className="col-12 ">
                                <h1>La Culture</h1><hr/>
                            </div>
                            
                            <div className="col-md-8 m-auto justify-content-center">
                                <p> Il y a trois idées qui guident chompp.</p>
                                <ul >
                                    <li>
                                        <p><strong>1. Axé sur l'expérience de l'utilisateur</strong></p>
                                    </li>
                                    <p>chompp. est un service dont le but est de fournir et de permettre des expériences. 
                                        Partager une table avec ses amis et sa famille famille, savourer un repas ou une boisson,
                                         économiser de l'argent, vivre le moment présent et créer des souvenirs, voilà ce qu'est chompp. 
                                         Vivez pour des conversations intéressantes. 
                                    </p>
                                        
                                    
                                    <li>
                                        <p><strong>2. Gentillesse</strong></p>
                                    </li>
                                    <p> chompp. est un service pour tous. Ne pas traiter ceux qui sont différents de vous comme des moins
                                         que rien permet de renforcer la communauté et d'enrichir l'expérience de chacun - pour tous. Soyez 
                                         amical avec vos serveurs, soyez amical avec vos clients. N'ayez pas peur d'offrir un verre à votre serveur. 
                                        Qui sait, peut-être allez-vous boire et partager des histoires toute la nuit.

                                    </p>
                                    <li>
                                        <p><strong>3. Soutenir le local </strong></p>
                                    </li>
                                    <p> chompp. s'engage à soutenir le local. Nous nous associons exclusivement avec des entreprises locales. 
                                        Pour cette raison, lorsque vous utilisez chompp. vous soutenez directement les entreprises locales. 
                                        Nous aidons à construire des communautés, pas des gratte-ciel. Nous encourageons tous les membres à 
                                        soutenir les entreprises locales.
                                    
                                    </p>
                                </ul> 
                                

                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <hr />
                    </div>
                    

                    
                </div>
            </div>
            <FrContact />
            <FrFooter />
        </>
    




)}
export default FrAbout;