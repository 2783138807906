import React, { useState, useEffect} from 'react';

import FrHeader from '../../FrHeader';
import FrFooter from '../../Footer/FrFooter';

import  "firebase/storage";
import { Link } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import {auth, db, addFavorite} from '../../../utils/firebase';
import { query, collection, getDocs, where } from "firebase/firestore";

import { doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
 

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {CardActionArea, CardActions } from '@mui/material';
import { Button } from 'react-bootstrap';

import './MyTrips.css';


function FrMyTrips() {

    //  fetch restaurants from firebase
    const [restaurants, setRestaurants] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const q = query(collection(db, "restuarants"));
                const doc = await getDocs(q);
                const data = doc.docs.map(doc => doc.data());
                setRestaurants(data);
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }

        }
        fetchData();
    }
    , []);

    // fetch user data 
    const [user, setUser] = useState(null);
    const [userLoading, setUserLoading] = useState(true);
    const [userError, setUserError] = useState(null);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const user = await auth.currentUser;
                setUser(user);
                setUserLoading(false);

            } catch (err) {
                setUserError(err);
                setUserLoading(false);
            }

        }
        fetchUser();
    }
    , []);

    


    return (
        <>
            <FrHeader />
        <div className="container" id="trips-container" >
            <div className="row content">
                <div className="col-12" id="head">
                    <h1>Mes sorties</h1>
                </div>
            </div>
            <div className='row' id="restaurants">
            <div className="col-12">
                    <div className='row'>
                        
                        <div className="col-4 d-flex">
                            <Card className="restaurantCard"  >
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="170"
                                        image= "https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg"
                                        
                                        
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">
                                        Arrive en Septembre
                                        </Typography>
                                        
                                        

                                    </CardContent>
                                </CardActionArea>
                            </Card>
                            <Card className="restaurantCard"  >
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="170"
                                                image= "https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg"
                                                
                                                
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="h2">
                                                Arrive en Septembre
                                                </Typography>
                                                
                                                

                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                    <Card className="restaurantCard"  >
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="170"
                                                image= "https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg"
                                                
                                                
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="h2">
                                                Arrive en Septembre
                                                </Typography>
                                                
                                                

                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </div>
                            </div>
                        </div>
           
            
            </div>
        </div>
        <FrFooter />
    </>
    );
}



                            
export default FrMyTrips;