import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword, signInWithGoogle } from "../../../utils/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "./Login.css";

function FrLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate("/tableau-de-bord");
  }, [user, loading]);

  


  return (
    <div className="login">
      <div className="container">
        <div className="row col-12">
          <div className="login_header col-12">
            <h1>Se connecter.</h1>
            <a href="/"><p>Retourner à la page d'accueil</p></a>
          </div>
          <div className="login__container col-12 col-sm-9 col-md-7 justify-content-center">
            <input
              type="text"
              className="login__textBox"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Adresse mail"
            />
            <input
              type="password"
              className="login__textBox"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Mot de passe"
            />
            <button
              className="login__btn"
              onClick={() => logInWithEmailAndPassword(email, password)}
            >
              Se Connecter
            </button>
            
            <div>
              <Link to="/reinitialiser">Mot de passe oublié</Link>
            </div>
            <div>
            Pas de compte? <Link to="/frreg">Inscrivez-vous ici</Link> 
            </div>
          </div>
        </div>
        </div>

      </div>
      
  );
}

export default FrLogin;