import React, { useState, useEffect} from 'react';

import  "firebase/storage";

import {auth, db, addFavorite} from '../../utils/firebase';
import { query, collection, getDocs} from "firebase/firestore";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {CardActionArea} from '@mui/material';
import { Button } from 'react-bootstrap';


import { useParams } from 'react-router-dom';

function FrUrl() {

    //  fetch restaurants from firebase
    const [restaurants, setRestaurants] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const q = query(collection(db, "restuarants"));
                const doc = await getDocs(q);
                const data = doc.docs.map(doc => doc.data());
                setRestaurants(data);
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }

        }
        fetchData();
    }
    , []);

    // fetch user data 
    const [user, setUser] = useState(null);
    const [userLoading, setUserLoading] = useState(true);
    const [userError, setUserError] = useState(null);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const user = await auth.currentUser;
                setUser(user);
                setUserLoading(false);

            } catch (err) {
                setUserError(err);
                setUserLoading(false);
            }

        }
        fetchUser();
    }
    , []);

    const {uid} =useParams();



    return (
        <div className="container" id="restaurants-container" >
            <div className="row content">
                <div className="col-12" id="head">
                    <h1>Sélectionnez un restaurant</h1>
                </div>
            </div>
            <div> uid : {uid} </div>
            <div className='row' id="restaurants">
                <div className="col-12">
                    <div className='row'>
                        
                        <div className="col-4 d-flex">
            

                                 {restaurants.map(restaurant => (

                                    <Card className="restaurantCard" key={restaurant.id} sx={{ maxWidth: 300, minWidth:300 }}>
                                        <CardActionArea>
                                            <CardMedia

                                                component="img"
                                                height="170"
                                                image={restaurant.image}
                                                title={restaurant.name}
                                                onClick={() => {
                                                    addFavorite(restaurant.name, uid);  
                                                    
                                                    
                                                }}

                                            />
                                            <CardContent>

                                                <Typography gutterBottom variant="h5" component="h2">
                                                    
                                                    {restaurant.name}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary" component="p">
                                                    <Button onClick={() => {
                                                    addFavorite(restaurant.name, uid) 
                                                    }}> 
                                                        Add to list 
                                                    </Button>
                                                </Typography>




                                                
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                    ))}
                                </div>
                            </div>
                        </div>
            </div>
            
        </div>

    );
}

/*
{restaurants.map(restaurant => (


                <Card className="restaurantCard" key={restaurant.id} sx={{ maxWidth: 300, minWidth:300 }}>
                    <CardActionArea>
                        <CardMedia

                            component="img"
                            height="170"
                            image={restaurant.image}
                            title={restaurant.name}
                            onClick={() => {
                                addFavorite(restaurant.name, uid);  
                                
                                
                            }}

                        />
                        <CardContent>

                            <Typography gutterBottom variant="h5" component="h2">
                                
                                {restaurant.name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                <Button onClick={() => {
                                addFavorite(restaurant.name, uid) 
                                }}> 
                                    Add to list 
                                </Button>
                            </Typography>




                            
                        </CardContent>
                    </CardActionArea>
                </Card>
            ))}*/



export default FrUrl;






                        
                    
                        
