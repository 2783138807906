// import react component
import React, { Component } from 'react';
import './Contact.css';

class Contact extends Component {
    render() {
        return (
            // Create Who we are component
             
                <div className="container" id="Contact">
                    <div className="row content">
                        <div className="col-12 d-flex justify-content-center" >
                            <h2>Want to find out more or interested in partnering with us? <a href="mailto: harry@chompp.ca">Get in touch</a></h2>
                        </div>
                    </div>
                </div>
            
        );
    }
}
export default Contact;