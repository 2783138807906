import { getFirestore, query, getDocs, getDoc, collection, where, addDoc, setDoc, getFirebaseStorage, arrayUnion, updateDoc, doc } from "firebase/firestore";

import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import {
  auth, db,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from "../../../utils/firebase";
import "./Register.css";

function FrRegister() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  const register = () => {
    if (!name) alert("Entrez votre nom");
    if (!email) alert("Entrez votre email");
    if (!password) alert("Entrez votre mot de passe");
    if (password.length < 6) alert("Le mot de passe doit contenir au moins 6 caractères");
    
    else {

        registerWithEmailAndPassword(name, email, password);
        
    }
  };

  useEffect(() => {
    if (loading) return;
    if (user) navigate("/tableau-de-bord");
  }, [user, loading]);

  // fetch products from firebase 
  const [products, setProducts] = useState([]);
  useEffect(() => {
    const subscribe = query(collection(db, "products"), where("active", "==", true)); 
    const q = getDocs(subscribe);

    
    q.then(docs => {
      // create an empty product object 
      const products = {};
      // loop through the docs id and add the doc id's to the products array
      docs.forEach(async doc => {
        products[doc.id] = doc.data();
        // get product prices 
        const prices = await getDoc(collection(db, "prices"), doc.id);

        // add the priceid and pricedata to the product object
        products[doc.id].price = prices.data();
    
    })
    // set the products array to the products object
    console.log('products', products);
    console.log('object entries', Object.entries(products));
    setProducts(products);
    }
    ).catch(err => {
      console.error(err);
      alert("Error");
    }
    );

  }, []);



  return (
    <div className="register">
        <div className="container">
          <div className="row col-12">
            <div className="register_header col-12">
              <h1>Rejoignez chompp. gratuitement aujourd'hui !</h1>
              <a href="/"><p>Retourner à la page d'accueil</p></a>
            </div>
            <div className="register__container col-12 col-sm-9 col-md-7 justify-content-center">
          <input
            type="text"
            className="register__textBox"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Nom complet"
          />
          <input
            type="text"
            className="register__textBox"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Adresse mail"
          />
          <input
            type="password"
            className="register__textBox"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Mot de passe"
          />
        
          
                <button className="register__btn" onClick={register}>
                S'inscrire ici
                </button>
              
            

          
          

          <div>
          Vous avez déjà un compte ?  <Link to="/frlgn">Connexion </Link> 
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

/*{Object.entries (products).map(([productId, productData]) => {
            return (
              <div className="products" key={productId}>
                <div>

                  {productData.name} - {productData.description}
                </div>
            */

export default FrRegister;