// import react component
import React, { Component } from 'react';
import './HowItWorks.css';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import {CardActionArea} from '@mui/material';

class HowItWorks extends Component {
    render() {
        return (
            
    
            <div className='jumbotron' id="Intro"> 
                <div className="container">
                    <div className="row content">
                        <div className="col-12  ">
                            <div className="head">
                                <h2>chompp. in a nutshell...</h2>
                            </div>
                        
                        </div>
                        
                        
                
                    
                        <div className="col-12">
                            <div className="Howsteps">
                            
                                <div className="row">
                                    <div className="col-lg-4  d-flex justify-content-center">
                                        <div className="step">
                                            <Card>
                                                <CardActionArea>
                                                    
                                                    
                                                    <CardMedia id="HowMedia"

                                                        component="img"
                                                        
                                                        image="https://images.squarespace-cdn.com/content/v1/518ea9e4e4b0eb1ecff22776/1487621886933-OQHP668XG6VWO06MVFVH/happy+diners?format=2500w"
                                                        title="Off"
                                                    />
                                                    <CardContent> 
                                                        <div className="step-text">
                                                            <h3>Discounts during quiet hours </h3>
                                                        </div>
                                                        
                                                    </CardContent>
                                                        
                                                    
                                                    
                                                </CardActionArea>
                                            </Card>
                                        </div>
                                    </div>
                                            
                                    <div className="col-lg-4  d-flex justify-content-center">
                                        <div className="step">
                                            <Card>
                                                <CardActionArea>
                                                    
                                                    <CardMedia  id="HowMedia"
                                                        component="img"
                                                        
                                                        image="https://www.springwise.com/wp-content/uploads/2014/11/IO_AI_restaurant_guide_app_Springwise.jpg"/>
                                                    <CardContent >
                                                        
                                                        <div className="step-text">
                                                            <h3>Recommendations in Montreal
                                                            </h3>
                                                        </div>
                                                        
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-lg-4  d-flex justify-content-center">
                                        
                                    <div className="step">
                                            <Card>
                                                <CardActionArea>
                                                    
                                                    <CardMedia id="HowMedia"
                                                        component="img"
                                                        
                                                        image="https://globalnews.ca/wp-content/uploads/2015/07/restaurants-e1437413671707.jpg?w=2048" />
                                                    

                                                    <CardContent  >
                                                        
                                                        <div className="step-text">
                                                            <h3>Supporting Local restaurants </h3>
                                                        </div>
                                                        
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </div>

                                    </div> 
                                </div>
                            </div> 
                        </div>
                            
                </div>
                </div>
                <div className='Hows'>
                
                    <div className="container">
                            <div className="row content">
                                <div className="col-12" id="hed">
                                    <h1>How it works</h1>
                                </div>
                            </div>
                    </div>

                    <div className="container">
                        <div className="row  ">
                            <div className="col-12">
                                <div className="Howstps">
                                    <div className="row">
                                        <div className="col-lg-3 d-flex">
                                            <div className="stp">                                  
                                                <div className="stp-text">
                                                    <h4>1. Join for free! <a href="/register" >Create your account here</a> </h4>
                                                </div>
                                                        
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="stp"> 
                                                <div className="step-text">
                                                    <h4>2. Choose a restaurant from one of our partnered restaurants
                                                    </h4>
                                                </div>                               
                                            </div>                               
                                        </div>
                                                
                                        <div className="col-lg-3">
                                            <div className="stp"> 
                                                <div className="stp-text">
                                                    <h4>3. Download your Dining Card or take a screenshot of the QR code 
                                                    </h4>
                                                </div>                               
                                            </div>                               
                                        </div>
                                        
                                        <div className="col-lg-3">
                                            <div className="stp">
                                                <div className="stp-text">
                                                    <h4>4. Show your Dining Card to the server and they will scan it   </h4>
                                                </div>
                                            </div>

                                        </div> 
                                    </div>
                                </div>

                            </div> 
                        </div>
                    </div> 
                </div>
                    
            </div>
            
        
           
        );
    }
}
export default HowItWorks;