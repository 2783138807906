// import react component
import React, { useEffect, useState } from "react";
import  "firebase/storage";
import { db} from '../../../utils/firebase';
import { query, collection, getDocs } from "firebase/firestore";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {  Button, CardActionArea} from '@mui/material';

import './Partners.css';

function Partners () {

// get restaurant data from firebase and create a card for each restaurant
        const [restaurants, setRestaurants] = useState([]);


        useEffect(() => {
            const fetchData = async () => {
                try {
                    const q = query(collection(db, "restuarants"));
                    const doc = await getDocs(q);
                    const data = doc.docs.map(doc => doc.data());
                    setRestaurants(data);
                    
                } catch (error) {
                    
                }

            }
            fetchData();
        }
        , []);

        return (
            // Create Who we are component
             <>
                <div className="container" id="Project">
                    <div className="row content">
                        <div className="col-12" >
                            <h2>See our partnered restaurants</h2>
                        </div>
                    </div>
                </div>
                <div className="container" id="restaurants-container" >
                    <div className='row' id="categories">
                        <div className="col-12">
                            <div className='row'>
                                <div className="col-4 d-flex">
                                
                                <Card className="restaurantCard" sx={{ maxWidth: 345 }}>
                                    <CardActionArea>
                                        <CardMedia

                                            component="img"
                                            height="140"
                                            image="https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM="
                                            alt="bowhead pub"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h5">
                                                chompp is currently on pause
                                            </Typography>
                                            
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                                
                                </div>
                            
                            
                            </div>
                        </div>
                    </div>
                </div>
            </>

        );
    }

    /* 
     {restaurants.map(restaurant => (
                                
                                <Card className="restaurantCard" key={restaurant.id} >
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="170"
                                            image={restaurant.image}
                                            title={restaurant.name}
                                            
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                {restaurant.name}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                {restaurant.discount} 
                                            </Typography>

                                            <Typography variant="body2" color="textSecondary" component="p">

                                                <Button>
                                                    <a href={restaurant.link}>
                                                        See Restaurant
                                                    </a>
                                                </Button>
                                            </Typography>
                                            
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            ))}*/
export default Partners;