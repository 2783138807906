import React, { useEffect, useState } from "react";
import  "firebase/storage";
import { Link } from 'react-router-dom';

import {db} from '../../../../utils/firebase';
import { query, collection, getDocs } from "firebase/firestore";

import './ViewAll.css';


import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea} from '@mui/material';

import HeaderLoggedIn from '../../../../components/HeaderLoggedIn';
import Footer from '../../../../components//Footer/Footer';

function ViewAll () {
    const [restaurants, setRestaurants] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const q = query(collection(db, "restuarants"));
                const doc = await getDocs(q);
                const data = doc.docs.map(doc => doc.data());
                setRestaurants(data);
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }

        }
        fetchData();
    }
    , []);

    // create a filter option to filter the restaurants by the cuisine
    const [cuisine, setCuisine] = useState('');
    const [filteredRestaurants, setFilteredRestaurants] = useState([]);

    useEffect(() => {
        if (cuisine) {
            const filtered = restaurants.filter(restaurant => restaurant.cuisine.includes(cuisine));
            setFilteredRestaurants(filtered);
        } else {
            setFilteredRestaurants(restaurants);
        }
    }, [cuisine, restaurants]);
    
    

    return (
        <>
            <HeaderLoggedIn />
            
            <div className="container" id="viewall">
                <div className="row content">
                    <div className="col-12" id="head">
                        <h1>List of Parterned Restaurants </h1>
                    </div>
                </div>
                

                
            
                <div className="container" id="restaurants-container" >
                    <div className='row' id="categories">
                        <div className="col-12">
                            <div className='row'>
                                <div className="col-4 d-flex">
                                <Card className="restaurantCard"  >
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="170"
                                        image= "https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg"
                                        
                                        
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            Coming in September
                                        </Typography>
                                        
                                        

                                    </CardContent>
                                </CardActionArea>
                            </Card>
                            <Card className="restaurantCard"  >
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="170"
                                                image= "https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg"
                                                
                                                
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="h2">
                                                    Coming in September
                                                </Typography>
                                                
                                                

                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                    <Card className="restaurantCard"  >
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="170"
                                                image= "https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg"
                                                
                                                
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="h2">
                                                    Coming in September
                                                </Typography>
                                                
                                                

                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                    

                                </div>
                            
                            
                            </div>
                        </div>
                    </div>
                    

                </div>
                
                <div className="container" id="pagination">
                    <div className="row">
                        <div className="col-12">
                            <div className="row justify-content-center">
                                <div className="col-3 col-sm-2">
                                    <a variant="contained" color="primary" className="pagination-button">
                                        Previous
                                    </a>

                                </div>
                                <div className="col-3 col-sm-2">
                                    <a variant="contained" color="primary" className="pagination-button">
                                        Next
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );


}

/*
{restaurants.map(restaurant => (
                                    <Card className="restaurantCard" key={restaurant.id} sx={{ maxWidth: 300, minWidth:300 }}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                height="170"
                                                image={restaurant.image}
                                                title={restaurant.name}
                                                
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="h2">
                                                    {restaurant.name}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary" component="p">
                                                    <Button>
                                                        <a href={restaurant.link}>
                                                            Book
                                                        </a>
                                                    </Button>
                                                </Typography>

                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                ))}
*/
export default ViewAll;