// create footer with copy right, contact information, links to pages, and social media

import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, logout } from '../../utils/firebase';

import './Footer.css';

function Footer(){

    // if user is not logged in, hide the footer
    const [user, loading] = useAuthState(auth);
    if (!user) return (
        <footer className="footer">
            <div className="footer-copyright">
                <div className="container">
                    <div className="row ">
                        <div className="col-12 d-flex justify-content-center ">
                            <p className="white-text">
                                chompp © 2022 - Website made by chompp.
                            </p>
                        </div>
                        
                    </div>
                </div>
            </div>


        </footer>

    );

    

    return(

    	<footer className="footer">	
            
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 m-b-30">
                        <div className="footer-title m-t-5 m-b-20 p-b-8">
                            About us
                        </div>	
                        <p className="white-text">
                            chompp. is a club for people who love to go out. We work with restaurants to offer discounts
                             to our members, which in turn allows restaurants to get more customers and members to save money. 
                        </p>
                    </div>
                    
                    <div className="col-lg-3 m-b-30">
                        <div className="footer-title m-t-5 m-b-20 p-b-8">
                            Quick Links
                        </div>	
                        <div className="footer-links">
                            <a href="/dashboard">
                                Dashboard
                            </a>
                            <a href="/about">
                                About Us
                            </a>
                            <a href="/settings">
                                My Account
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 m-b-30">
                        <div className="footer-title m-t-5 m-b-20 p-b-8">
                            Help Desk 
                        </div>	
                        <div className="footer-links">
                            <a href="/how">
                            How to use my Dining card
                            </a>
                            <a href="/help">
                            Popular Questions
                            </a>
                        
                            <a href="/help">
                            Contact us 
                            </a>
                        </div>

                        
                    </div>
                </div>
                 
                


            </div>
            
            <div className="footer-copyright">
                <div className="container">
                    <div className="row ">
                        <div className="col-12 d-flex justify-content-center ">
                            <p className="white-text">
                                chompp © 2022 - Website made by chompp.
                            </p>
                        </div>
                        
                    </div>
                </div>
            </div>


    </footer>
	
    );
}

export default Footer;




