// create settings component
import React from 'react';
import FrHeader from '../FrHeader';
import FrFooter from '../Footer/FrFooter';

// change email address in firebase auth
import "./settings.css";
import { query, collection, getDocs, where } from 'firebase/firestore';

//forgotten email address
import { useEffect } from 'react';
import { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { auth, db, logout, doneSuccess } from '../../utils/firebase';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {  CardActionArea } from '@mui/material';


function FrSettings() {

    const [user, loading] = useAuthState(auth);  
    const navigate = useNavigate();
    const [setUserError] = useState(null);
    const [email, setEmail] = useState(null);
    
    useEffect(() => {
        if (loading) return;
        
    }, [user, loading]);

    const [name, setName] = useState("");
  

    const fetchUserName = async () => {
        try {
        const q = query(collection(db, "users"), where("uid", "==", user?.uid));
        const doc = await getDocs(q);
        const data = doc.docs[0].data();

        setName(data.name);
        } catch (err) {
        console.error(err);
        alert("Error avec prenom");
        }
    };

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");

        fetchUserName();
    }, [user, loading]);

    const authy = getAuth();

    const handleResetPassword = async () => {
        try {
            await sendPasswordResetEmail(authy, email);
            doneSuccess();
        } catch (error) {
            setUserError(error.message);
        }
    };

// fetch users email and password from firebase auth

    const fetchUserEmail = async () => {    //fetch user email from firebase auth
        try {

        const user = auth.currentUser;
        const email = user.email;
        setEmail(email);
        } catch (err) {
        console.error(err);
        alert("Error avec email");
        }
    };
    
    useEffect(() => {
        if (loading) return;
        if (!user) return;

        fetchUserEmail();
    }, [user, loading]);




    return (
        <>
        <FrHeader />
        <div className="jumbotron" id="account">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h1>Mon Compte </h1>
                        
                    </div>
                </div>

            </div>
        </div>

        <div className="container" id="account-container" >
            

           
                <div className="col-12">
                    <div className="row">
                        <div className=" d-flex align-content-around flex-wrap">
                            
                            
                            <Link to="/aide">
                                <Card className="categoriesCard" sx={{ maxWidth: 300, minWidth:300 }}>
                                    <CardActionArea>
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                <a href="/aide">Services Clients </a>
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Link>
                            
                            <Link to="/">
                                <Card className="categoriesCard" color="blue" sx={{ maxWidth: 300, minWidth:300 }}>
                                    <CardActionArea>
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                <a href="/" onClick={handleResetPassword}>Réinitialiser mon mot de passe</a>
                                            </Typography>
                                            
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Link>

                            <a href="/fr">
                                <Card className="categoriesCard" onClick={logout} sx={{ maxWidth: 300, minWidth:300 }}>
                                    <CardActionArea>

                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                <a href="/">Se déconnecter</a>
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </a>
                            

                        </div>
                    </div>
                    
                    
                </div>
                
            
            
            
        </div>
        <FrFooter/>
        </>
            
        
    );


}
export default FrSettings;




                        
