import React, { Component } from 'react'

import FrHeader from '../../FrHeader';
import FrFooter from '../../Footer/FrFooter';
import './HowToUseCard.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import {CardActionArea} from '@mui/material';


import './HowToUseCard.css';


class FrHow extends Component {
    render() {
        return (
            <>
                <FrHeader />
                <div className='jumbotron' id="Howheader">
                    <div className="container">
                        <div className="row ">
                            <div className="col-12 d-flex justify-content-center ">
                                <div className="head">
                                    <h2>Comment utiliser ma carte chompp?</h2>
                                </div>
                            </div> 
                        </div>
                    </div> 
                </div>
                <div className='jumbotron' id="Howjumbo">
                    <div className="container">
                            <div className="row content">
                                <div className="col-12" id="head">
                                    <h1>Trois étapes</h1>
                                </div>
                            </div>
                    </div>
                    <div className="container">
                        <div className="row  ">
                            <div className="col-12">
                                <div className="Howsteps">
                                    <div className="row">
                                        <div className="col-12 col-lg-4 d-flex">
                                            <div className="step">
                                                <Card>
                                                    <CardActionArea>
                                                        <CardContent> 
                                                            <div className="step-text">
                                                                <h4>1. Choissez un restaurant parmi nos partenaires</h4>
                                                            </div>
                                                            
                                                            <div className="step-text d-flex justify-content-center">
                                                                <a href="/voirtous">Voir les restaurants</a>
                                                            </div>
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </div>
                                        </div>
                                                
                                        <div className="col-12 col-lg-4">
                                        <div className="step">
                                                <Card>
                                                    <CardActionArea>
                                                        <CardContent >
                                                            
                                                            <div className="step-text">
                                                                <h4>2. Téléchargez la carte chompp ou prenez une capture d’écran du QR code. 
                                                                </h4>
                                                            </div>
                                                            <div className="step-text d-flex justify-content-center">
                                                                <a href="/frqr">Télécharger ici</a>
                                                            </div>
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </div>
                                            
                                            
                                        </div>
                                        <div className="col-lg-4">
                                            
                                        <div className="step">
                                                <Card>
                                                    <CardActionArea>
                                                        <CardContent  >
                                                            
                                                            <div className="step-text">
                                                                <h4>3. Montrez ta carte chompp au serveur pour qu’il la scan </h4>
                                                            </div>
                                                            <div className="step-text d-flex justify-content-center">
                                                                <a href="/">Appréciez votre repas</a>
                                                            </div>
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </div>

                                        </div> 
                                    </div>
                                </div>

                            </div> 
 
                        </div> 
                    </div>
                     
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="help">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="help-text d-flex justify-content-center">
                                                <p>
                                                Si vous avez d'autres questions ou problèmes, <a href="/aide">Essaye nos services clients </a>
                                                </p>
                                                
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div> 
                
            
                <FrFooter />
            </>
        );
    }
}

export default FrHow;

