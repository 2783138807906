import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";
import {Link } from "react-router-dom";

import { auth, db, logout } from "../../utils/firebase";
import { query, collection, getDocs, where } from "firebase/firestore";

import FrRestaurantCard from "./Restaurants/RestaurantCard/FrRestaurantCard";

import FrCategories from "./Categories/FrCategories";
import FrFooter from "../Footer/FrFooter";
import FrHeader from "../FrHeader";
import FrMyTrips from "./MyTrips/FrMyTrips";
import FrUserUrl from "../userUrl/FruserUrl";
import FrSavings from "./Savings/FrSavings";

function FrDashboard() {
  const [user, loading] = useAuthState(auth);
  const [name, setName] = useState("");
  const navigate = useNavigate();

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();

      setName(data.name);
    } catch (err) {
      console.error(err);
      alert("Error");
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");


    fetchUserName();
  }, [user, loading, navigate]);







  return (

              <>
                <FrHeader />
                <div className="d">
                    
                    <div className="container" id="dashboard">
                        <div className="row">
                            <div className="col-12" id="titles">
                                <h6>Salut {name}! </h6>
                                <h6 id="wel">Bienvenue sur chompp.</h6>
                                
                                
                                  
                            </div>
                            <div className="col-12 " id="cards">
                                
                                  <Link to="/frqr">
                                    <button className="btn btn-primary"  id="gold">Télécharger ma carte chompp.</button>
                                  </Link>
                                
                                
                                  < Link to="/comment">
                                    <button className="btn" id="grey">Comment utiliser ma carte chompp.</button>
                                  </Link>
                                
                                
                            </div>
                        </div>
                    </div>
                    
                    <FrRestaurantCard/>
                    <FrSavings />
                    
                    
                    
                     
                    <div className="container" id="help">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                                <h2>Besoin d’aide? <a href='/aide'> Essaye nos services clients</a></h2>
                            </div>
                        </div>
                    </div>

                    
                </div>
                <FrFooter/>
              </>
  );
}

export default FrDashboard;