// create page for when an action is completed
//


function Done() {
    return (
        <div>
            <div className="container" id="done-container" >
                <div className='row' id="done">
                    <h1>Done</h1>
                </div>
                <div>
                    <a href="/">
                        return to home
                    </a>
                </div>
            </div>
        </div>
    );
    }

export default Done;