// create QR code for each user 
import React, {  useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../utils/firebase';

import { query, collection, getDocs, where } from 'firebase/firestore';

import HeaderLoggedIn from '../HeaderLoggedIn';
import Footer from '../Footer/Footer';

import './userUrl.css';


import QRCode from "react-qr-code";

// create QR code for each user
function UserUrl() {
    const [user, loading] = useAuthState(auth);
    const [name, setName] = useState("");
    const navigate = useNavigate();
    
    const fetchUserName = async () => {
        try {
          const q = query(collection(db, "users"), where("uid", "==", user?.uid));
          const doc = await getDocs(q);
          const data = doc.docs[0].data();
    
          setName(data.name);
        } catch (err) {
          console.error(err);
          alert("An error occured while fetching user data");
        }
      };
    
      useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
    
    
        fetchUserName();
      }, [user, loading, navigate]);


    //each user has their own web page for their favorite restaurants
    //create QR code for each user
    const [useUrl, setUseUrl] = useState(null);
    const [useUrlLoading, setUseUrlLoading] = useState(true);
    const [useUrlError, setUseUrlError] = useState(null);

    useEffect(() => {
        const fetchUseUrl = async () => {
            try {
                const useUrl = await auth.currentUser;
                setUseUrl(useUrl);
                setUseUrlLoading(false);
            } catch (err) {
                setUseUrlError(err);
                setUseUrlLoading(false);

            }
        }

        fetchUseUrl();
    }
    , []);

    // get uid from database
    const [uidv, setUid] = useState(null);
    const [uidLoading, setUidLoading] = useState(true);
    const [uidError, setUidError] = useState(null);

    useEffect(() => {
        const fetchUid = async () => {
            try {
                const uidv = auth.currentUser.uid;
                setUid(uidv);
                setUidLoading(false);
            } catch (err) {
                setUidError(err);
                setUidLoading(false);
            }
        }

        fetchUid();
    }
    , []);

    

    const urlValue = `http://chompp.ca/myurl/${uidv}`;

    

    return (
        <>
            <HeaderLoggedIn />
        
            <div className="container" id="qr-container" >
                <div className="row content">
                    <div className="col-12" id="head">
                        <h1>My Dining Card </h1>
                        
                        
                    </div>
                    <hr size="10" width="90%" color="grey"></hr>
                </div>
                <div className='row content' id="diningcard">
                    <div className='col-12 col-md-7' id="cardtitle">
                        <h2>
                            Name: {name}
                        </h2>
                        <h2 id="userid">
                            User Id : {uidv}
                        </h2>
                        
                    </div>
                
                    <div className='row content col-12 col-md-5 d-flex justify-content-center' id="qr">
                    
                            <QRCode value={urlValue} size={160} />
                        
                    </div>
                </div>
                <hr size="10" width="90%" color="grey"></hr>
                    
                     
            </div>
                <div className='container' id="smartphone">
                    <div className="row align-items-center">
                        <div className="col-12 col-sm-12" id="elcome">
                            <h3>  Take a Screenshot of your QR code to show servers </h3>
                        </div>
                    </div>
                </div>
                
            
            <Footer />
        </>

    );
}



    

export default UserUrl; 



