// Create restaurant card 
import React, { useEffect, useState } from "react";
import  "firebase/storage";
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { auth,  db} from '../../../utils/firebase';
import { query, collection, getDocs, where } from "firebase/firestore";

import './Savings.css';

// get restaurant data from firebase and create a card for each restaurant

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

function FrSavings() {
    
  // get user's est_savings from firebase
    const [user, loading] = useAuthState(auth);
    const [name, setName] = useState('');
    const [est_savings, setSavings] = useState(0);
    const [favorites, setFavorites] = useState([]);
    const navigate = useNavigate();
    
    const fetchSavings = async () => {
        try {
          const q = query(collection(db, "users"), where("uid", "==", user?.uid));
          const doc = await getDocs(q);
          const data = doc.docs[0].data();
    
          setSavings(data.est_savings);
        } catch (err) {
            alert("An error occured while fetching user data--send us an email");
        }
      };
    
      useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
    
    
        fetchSavings();
      }, [user, loading, navigate]);


    // get user's favorites from firebase
    const fetchFavorites = async () => {
        try {
            const q = query(collection(db, "users"), where("uid", "==", user?.uid));
            const doc = await getDocs(q);
            const data = doc.docs[0].data();
            setFavorites(data.favorites); 
            console.log (data.favorites);  
        } catch (err) {
            alert("An error occured while fetching user data -- send us an email");
        }
    };

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
        fetchFavorites();
    }, [user, loading, navigate]);

    //fetch email 
    const fetchName = async () => {
        try {
            const q = query(collection(db, "users"), where("uid", "==", user?.uid));
            const doc = await getDocs(q);
            const data = doc.docs[0].data();
            setName(data.name);
        } catch (err) {
            console.error(err);
            alert("An error occured while fetching user data");
        }
    };

    useEffect(() => {
        if (loading) return;
        
        fetchName();

    }, [user, loading, navigate]);


    //check if favorites array is empty 
    const isEmpty = (arr) => {
        return arr.length === 0;
    }

    // display the last 3 restaurants in the user's favorites list
    const lastThree = favorites.slice(Math.max(favorites.length - 4, 0))

    //reverse the order of the array so that the last 4 restaurants are displayed first
    const reversed = lastThree.reverse();

    // get est_savings to 2 decimal places
    const est_savings_fixed = est_savings.toFixed(2);
    //find length of favorites array
    const num_trips = favorites.length;

    return (
        <>
         <div className="container" id="ccontainer">
            <div className="container">
                <div className="row content">
                    <div className="col-12" id="head">
                        <h1>Mon Profil</h1>
                    </div>
                </div>
            </div>
            <div className="container justify-content-center" id="restaurants-container" >
                <div className='row' id="categories">
                        <div className="col-12 d-flex align-content-around flex-wrap" id="savings">
                            <div className="col-lg-7 col-12 flex">
                                <div className="col-12">
                                    <div className="col-12">
                                    <h5 id="est_savingstitle">Économies totales estimées</h5>
                                    <h3 id="est_savings"> ${est_savings_fixed}</h3>
                                    </div>
                                </div>
                                <div className="col-12 d-flex align-content-around flex-wrap" id="bottom_savings">
                                    <div className="col-sm-6 " id="account_savings">
                                    <h5>Nombre de voyages</h5>
                                        <h3  id="num_trips"> {num_trips}</h3>
                                    </div>
                                    <div className="col-sm-6   flex-wrap" id="account_savings">
                                            <h5 id="savingsname">{name}</h5>
                                            <h5 id="savingsname"><a href="/compte">Mon Compte</a></h5>   
                                        </div>
                                    
                                    
                                </div>
                            </div>
                            
                            <div className="col-lg-5 col-md-10 col-12 justify-content-center" id="recenttrips">
                                <div className="col-12">
                                    <div className="col-12">
                                        <h5>Voyages récents:</h5>
                                    </div>
                                </div>

                                
                                <div className="col-12">
                                    <div className="col-12">
                                        <div className="col-12 justify-content-center" id="cnt_cards">
                                
                                     
                                            {isEmpty(favorites) ? <p id="empty"> Pas de voyages récents</p> :
                                                reversed.map((item) => (
                                                    <div className="col-12" id="trip_cards">
                                                        <Card id="trips_cards" >
                                                            <CardContent>
                                                                    <Typography  gutterBottom variant="p" component="div" > 
                                                                        {item}
                                                                    </Typography>
                                                                </CardContent>
                                                        </Card>
                                                    </div>
                                                ))}

                                            </div>
                                    </div>
                                        
                                </div>       
                            </div>
                        </div>
                </div>
            </div>
        </div>
        </>
    );
}

/*
                                */


export default FrSavings;
