// create page with the club rules in it
import React, { Component, useEffect, useState } from 'react'
import { Jumbotron } from 'reactstrap';
import HeaderLoggedIn from '../HeaderLoggedIn';
import Footer from '../Footer/Footer';

import { auth, db, logout } from "../../utils/firebase";
import { query, collection, getDocs, where } from "firebase/firestore";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardHeader } from '@mui/material';


// import rules css 
import "./Rules.css";

             



function Rules() {


        // fetch all the dinalations from firebase
        const [dinalations, setDinalations] = useState([]);
        const [setLoading] = useState(true);

        useEffect(() => {
            const fetchData = async () => {
                try {
                    const q = query(collection(db, "Dinalations"));
                    const doc = await getDocs(q);
                    const data = doc.docs.map(doc => doc.data());
                    setDinalations(data);
                    setLoading(false);
                } catch (error) {
                    console.log(error);
                }

            }
            fetchData();

        }
        , []);


        return (
            <>
                <HeaderLoggedIn />
                <div className='jumbotron' id="Rulesheader">
                    <div className="container">
                        <div className="row ">
                            <div className="col-12 d-flex justify-content-center ">
                                <div className="head">
                                    <h3>Dinalations</h3>
                                </div>
                            </div> 
                        </div>
                    </div> 
                </div>
                <div className="Rule">
                    <div className="container">
                        <div className="row" id="Rules">
                            <div className="col-12 col-lg-10  m-auto">
                            
                                    <h5>
                                         I don't want to pretend to care about what you do - because I don't. Whether you gobble like a retriever or sweat like Prince Andrew is none of my business. 
                                         But as this is a club, and we're friends, I thought I'd share some in's and out's of going out. Stuff which they really ought to teach you at school. And who knows, maybe it'll help you secure that job or better yet, get you laid. 
                                         <br /> <br/> 
                                    </h5>
                                    
                                    <h5> 
                                        Signed, Harry North 
                                    </h5>
                            </div>   
                        </div>
                    </div>
                </div>

                <div className="container" id="dinalations-container">
                    <div className='row' id="categories">
                        <div className="col-12">
                            <div className='row'>
                                <div className="col-4 m-auto ">
                                    {dinalations.map(dinalation => (
                                        <Card className="dinalationCard">
                                            <CardActionArea>
                                            
                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                        {dinalation.content}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary" component="p">
                                                        {dinalation.title}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }

export default Rules;