import React from 'react';
import {Link } from "react-router-dom";

import './Welcome.css';

import { auth } from "../../../utils/firebase";

import { useAuthState } from "react-firebase-hooks/auth";



function FrWelcome () {
    
    // if user is logged in, link to dashboard
    // if user is not logged in, link to login page
    const [user] = useAuthState(auth);

    const loggedIn = () => {
        if (user) {
            return (
                <Link to="/tableau-de-bord">
                    <button className='btn btn-primary col-12' id="Sub">Tableau de bord</button>
                </Link>
            )
        } else {
            return (
                <Link to="/frreg">
                    <button className='btn btn-primary col-12' id="Sub">S'inscrire gratuitement</button>
                </Link>
            )
        }
    }

        return (
            <>
                <div className='jumbotron' id="buy">
                    <div className="container">
                        <div className="row justify-content">
                            <div className="col-12  col-lg-8">
                                
                                <div className="c">
                                    <div className="card-header">
                                        <h2>Réductions pendant les heures creuses </h2>
                                    </div>
                                    <div className="card-body">
                                            <p>Voici la carte chompp: remises et recommandations personnalisées sur des restaurants à Montréal!
                                            </p>
                                    </div>
                                    <div className="card-footer d-flex justify-content-center">
                                        <Link to="/frreg">
                                            {loggedIn()}
                                        </Link>

                                                               
                                    </div>
                                </div>
                            </div>
                        
                                  

                            
                        </div>

                     </div>   
                    
                </div>
                <div className='container' id="welcome">
                    <div className="row align-items-center">
                        <div className="col-12 col-sm-12" id="Welcome">
                            
                            <h1> chomppp est actuellement en pause </h1>
                        </div>
                    </div>
                </div>
                
            </>

            


        );
    }

export default FrWelcome;