import React, { Component } from 'react'

import HeaderLoggedIn from '../../HeaderLoggedIn';
import Footer from '../../Footer/Footer';
import './HowToUseCard.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import {CardActionArea} from '@mui/material';


import './HowToUseCard.css';


class How extends Component {
    render() {
        return (
            <>
                <HeaderLoggedIn />
                <div className='jumbotron' id="Howheader">
                    <div className="container">
                        <div className="row ">
                            <div className="col-12 d-flex justify-content-center ">
                                <div className="head">
                                    <h2>How to use my Dining Card</h2>
                                </div>
                            </div> 
                        </div>
                    </div> 
                </div>
                <div className='jumbotron' id="Howjumbo">
                    <div className="container">
                            <div className="row content">
                                <div className="col-12" id="head">
                                    <h1>Three Steps</h1>
                                </div>
                            </div>
                    </div>
                    <div className="container">
                        <div className="row  ">
                            <div className="col-12">
                                <div className="Howsteps">
                                    <div className="row">
                                        <div className="col-12 col-lg-4 d-flex">
                                            <div className="step">
                                                <Card>
                                                    <CardActionArea>
                                                        <CardContent> 
                                                            <div className="step-text">
                                                                <h4>1. Choose a restaurant from one of our partnered restaurants - and be sure to book inadvance</h4>
                                                            </div>
                                                            
                                                            <div className="step-text d-flex justify-content-center">
                                                                <a href="/viewall">View restaurants</a>
                                                            </div>
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </div>
                                        </div>
                                                
                                        <div className="col-12 col-lg-4">
                                        <div className="step">
                                                <Card>
                                                    <CardActionArea>
                                                        <CardContent >
                                                            
                                                            <div className="step-text">
                                                                <h4>2. Download your Dining Card into your smartphone wallet or take a screenshot of the QR code. 
                                                                </h4>
                                                            </div>
                                                            <div className="step-text d-flex justify-content-center">
                                                                <a href="/qr">Download Dining Card</a>
                                                            </div>
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </div>
                                            
                                            
                                        </div>
                                        <div className="col-lg-4">
                                            
                                        <div className="step">
                                                <Card>
                                                    <CardActionArea>
                                                        <CardContent  >
                                                            
                                                            <div className="step-text">
                                                                <h4>3. Show your Dining Card to the restaurant staff when you arrive, they will scan it for you </h4>
                                                            </div>
                                                            <div className="step-text d-flex justify-content-center">
                                                                <a href="/en">Enjoy your meal</a>
                                                            </div>
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </div>

                                        </div> 
                                    </div>
                                </div>

                            </div> 
 
                        </div> 
                    </div>
                     
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="help">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="help-text d-flex justify-content-center">
                                                <p>
                                                    If you have any further questions or problems, <a href="/help">visit our help desk </a>
                                                </p>
                                                
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div> 
                
            
                <Footer />
            </>
        );
    }
}

export default How;

