import HeaderLoggedIn from "../HeaderLoggedIn";
import Footer from "../Footer/Footer";

import React from "react";

function Launching () {
    return (
        <>
            <HeaderLoggedIn />
            
                <div className="container" id="Launchingheader">
                    <div className="row ">
                        <div className="col-12 d-flex justify-content-center ">
                            <div className="head">
                                <h2>chompp. is launching soon</h2>
                            </div>
                        </div> 
                    </div> 
                </div>
            
            
                <div className="container">
                    <div className="row content">
                        <div className="col-12" id="head">
                            <h2> View partnered restaurants and download your chompp. dining card in September</h2>
                        </div>
                    </div>
                </div>
                
                <div className="container">
                    <div className="row content">
                        <div className="col-12" id="head">
                            <h1>In the meantime, relax, watch some golf, oh and follow us on Instagram ---@dinewithchompp</h1> 
                        </div>
                    </div>
                </div>

            
            <Footer />
        </>
    );
}

export default Launching;