import { FirebaseError, initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { GoogleAuthProvider, getAuth, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, updateEmail, signOut } from "firebase/auth";
import { getFirestore, query, getDocs, getDoc, collection, where, addDoc, setDoc, getFirebaseStorage, arrayUnion, updateDoc, doc } from "firebase/firestore";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAuiBJ3c9PXpam9loPGpbEWtW79o7kPYsA",
  authDomain: "northshouse-6b40f.firebaseapp.com",
  projectId: "northshouse-6b40f",
  storageBucket: "northshouse-6b40f.appspot.com",
  messagingSenderId: "378924114203",
  appId: "1:378924114203:web:28acda635cfdfc8aa473be",
  measurementId: "G-28Y2H53MQ4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const registerWithEmailAndPassword = async (name, email, password) => {
  // add users to firestore
  try {
    await createUserWithEmailAndPassword(auth, email, password);
    const user = auth.currentUser;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name: name,
      authProvider: "email",
      email: email,
      favorites: [],
      est_savings: 0,
    });
  }
  catch (err) {
    console.error(err);
    alert(err.message);
  }

};
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    // success message
    doneSuccess();
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};


 
const getDate = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = today.getFullYear();
  return `${yyyy}-${mm}-${dd}`;
}


// array Union a restaurant to the restuarant's favorites
const addFavorite = async (restaurant, uid) => {
  // get user's document id 
  
  const q = query(collection(db, "users"), where("uid", "==", uid));
  const docs = await getDocs(q);
  const docId = docs.docs[0].id;
  
    try {
      const ref = doc(db, "users", docId);
      const Restdate = restaurant + ", " + getDate();
      await updateDoc(ref, {

        // add the date to the favorites array
        
        favorites: arrayUnion(Restdate) 

      });
      doneSuccess();
    }
    catch (err) {
      console.error(err);
      alert(err.message);

    }
    
    
  }


// iterate through every user in the database and add savings number field
const addSavings = async () => {
  const q = query(collection(db, "users")); 
  const docs = await getDocs(q);
  docs.forEach(async (doc) => {
    const ref = doc(db, "users", doc.id);
    await updateDoc(ref, {
      savings: 0
    });
  });
}





  // redirect to done page
const doneSuccess = () => {
  window.location.href = "/done";
}




// change email address
const changeEmail = async (email) => {
  try {
    const user = auth.currentUser;
    await updateEmail(user, email);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
}


const logout = () => {
  signOut(auth);
};
export {
  auth,
  app,
  db, changeEmail, 
  logout, addFavorite,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset, doneSuccess, addSavings, 
  

};

