import { getFirestore, query, getDocs, getDoc, collection, where, addDoc, setDoc, getFirebaseStorage, arrayUnion, updateDoc, doc } from "firebase/firestore";

import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import {
  auth, db,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from "../../../utils/firebase";
import "./Register.css";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  const register = () => {
    if (!name) alert("Please enter name");
    registerWithEmailAndPassword(name, email, password);
  };

  useEffect(() => {
    if (loading) return;
    if (user) navigate("/dashboard");
  }, [user, loading]);

  // fetch products from firebase 
  const [products, setProducts] = useState([]);
  useEffect(() => {
    const subscribe = query(collection(db, "products"), where("active", "==", true)); 
    const q = getDocs(subscribe);

    
    q.then(docs => {
      // create an empty product object 
      const products = {};
      // loop through the docs id and add the doc id's to the products array
      docs.forEach(async doc => {
        products[doc.id] = doc.data();
        // get product prices 
        const prices = await getDoc(collection(db, "prices"), doc.id);

        // add the priceid and pricedata to the product object
        products[doc.id].price = prices.data();
    
    })
    // set the products array to the products object
    console.log('products', products);
    console.log('object entries', Object.entries(products));
    setProducts(products);
    }
    ).catch(err => {
      console.error(err);
      alert("An error occured while fetching products");
    }
    );

  }, []);



  return (
    <div className="register">
        <div className="container">
          <div className="row col-12">
            <div className="register_header col-12">
              <h1>Join chompp. for free!</h1>
              <a href="/en"><p>Go back to homepage</p></a>
            </div>
            <div className="register__container col-12 col-sm-9 col-md-7 justify-content-center">
          <input
            type="text"
            className="register__textBox"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Full Name"
          />
          <input
            type="text"
            className="register__textBox"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-mail Address"
          />
          <input
            type="password"
            className="register__textBox"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />

          <p>By registering and using chompp. you agree to our terms and conditions</p>

        
          
                <button className="register__btn" onClick={register}>
                  Register
                </button>
              
            

          
          

          <div>
            Already have an account? <Link to="/login">Login</Link> now.
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

/*{Object.entries (products).map(([productId, productData]) => {
            return (
              <div className="products" key={productId}>
                <div>

                  {productData.name} - {productData.description}
                </div>
            */

export default Register;