// import react 
import React, { useState, useEffect } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

import {Link } from "react-router-dom";

import { auth, db, logout } from "../../../utils/firebase";
import { query, collection, getDocs, where } from "firebase/firestore";

import MyTrips from '../MyTrips/MyTrips';


function MyCard () {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const navigate = useNavigate();

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();

      setName(data.name);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");

    fetchUserName();
  }, [user, loading]);

    return (
        <>
            <nav className="navbar navbar-expand-sm fixed top">
                        
                <div className='container' /*controls width*/> 
                    < button className='navbar-toggler' type="button" data-toggle="collapse" data-target="#Navbar">
                        <span className='navbar-toggler-icon'></span>
                    </button>
                        <a className="navbar-brand" href="#">
                            Montreal Dining Club
                        </a> 
                            
                        <div className='collapse navbar-collapse' id="Navbar">
                            <ul className='navbar-nav ml-auto'>
                                <li className='nav-item'>
                                    <Link className='nav-link' to="/settings"> 
                                        My Account 
                                    </Link>
                                </li>
                            </ul>
                        </div>
                                
                </div>
                        
                        
            </nav>
            <div className="container" id="dashboard">
                <div className="row">
                    <div className="col-12" id="titles">
                        <h6>{name}</h6>
                        <button className="btn btn-primary">Download my QR code</button>
                    </div>
                </div>
            </div>
            <MyTrips />
        </>
    );
}
export default MyCard;